import '@styles/style.scss';


import {initMenu} from './menu';
import {initTabs} from './tabs';
import {initModal} from './modal';
import {initCarousel} from './carousel';
import {initForm} from './booking'


initMenu();
initTabs();
initModal();
new WOW().init();
initCarousel();
initForm();

